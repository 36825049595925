import {
  roundBy,
  precisionRound,
  KmToMiles,
  KmToKnot,
  MetresToFeet,
  MillimetersToInches,
  CelsiusToFahrenheit,
} from './helpers';

class Units {
  constructor() {
    this.current = {
      temperature: 'Metric',
      length: 'Metric',
      speed: 'Metric',
    };
    this.listeners = [];
  }

  change(changes) {
    this.current = { ...this.current, ...changes };
    this.listeners.forEach(l => {
      try {
        l(changes);
      } catch (e) {
        console.error(e);
      }
    });
  }

  onChange(cb) {
    this.listeners.push(cb);
  }

  formatLength(val, { precision = 2, altUnits = false, withUnits = false } = {}) {
    let cval = val;
    let units = this.current.length;
    if (altUnits) units = units === 'Imperial' ? 'Metric' : 'Imperial';

    if (units === 'Imperial') {
      cval = MetresToFeet(val);
    }

    cval = precisionRound(cval, precision);

    return withUnits ? `${cval}${this.formatLengthUnits({ altUnits })}` : cval;
  }

  formatLengthUnits({ altUnits = false } = {}) {
    let units = this.current.length;
    if (altUnits) units = units === 'Imperial' ? 'Metric' : 'Imperial';

    if (units === 'Imperial') return 'ft';
    return 'm';
  }

  formatSnow(val, { precision = 1, precisionLimit = 1.8, altUnits = false, withUnits = false } = {}) {
    let cval = val;
    let units = this.current.length;
    if (altUnits) units = units === 'Imperial' ? 'Metric' : 'Imperial';

    if (units === 'Imperial') {
      cval = MillimetersToInches(val * 10);
    }

    const k = val > precisionLimit ? 1 : 10 ** precision;
    cval = Math.round(cval * k) / k;

    return withUnits ? `${cval}${this.formatSnowUnits({ altUnits })}` : cval;
  }

  formatSnowUnits({ altUnits = false } = {}) {
    let units = this.current.length;
    if (altUnits) units = units === 'Imperial' ? 'Metric' : 'Imperial';

    if (units === 'Imperial') return 'in';
    return 'cm';
  }

  formatMm(val, { precision = 1, precisionLimit = 2, altUnits = false, withUnits = false } = {}) {
    let cval = val;
    let units = this.current.length;
    if (altUnits) units = units === 'Imperial' ? 'Metric' : 'Imperial';

    if (units === 'Imperial') {
      cval = MillimetersToInches(val);
    }

    let prec = precision;
    if (cval > precisionLimit) prec = 0;
    else if (cval < 0.1) prec += 1;

    const k = 10 ** prec;
    cval = Math.round(cval * k) / k;

    return withUnits ? `${cval}${this.formatMmUnits({ altUnits })}` : cval;
  }

  formatMmUnits({ altUnits = false } = {}) {
    let units = this.current.length;
    if (altUnits) units = units === 'Imperial' ? 'Metric' : 'Imperial';

    if (units === 'Imperial') return 'in';
    return 'mm';
  }

  formatSwell(val) {
    let cval = val;
    const units = this.current.length;

    if (units === 'Imperial') {
      cval = MetresToFeet(val);
      if (cval < 10) cval = roundBy(cval, 0.5);
    }

    return cval >= 10 ? Math.round(cval) : precisionRound(cval, 1);
  }

  formatSpeed(val, { withUnits = false, precision = 5 } = {}) {
    let cval = val;
    const units = this.current.speed;

    if (units === 'Imperial') {
      cval = KmToMiles(val);
    } else if (units === 'Knot') {
      cval = KmToKnot(val);
    }

    cval = roundBy(cval, precision);

    return withUnits ? `${cval}${this.formatSpeedUnits()}` : cval;
  }

  formatSpeedUnits() {
    const units = this.current.speed;

    if (units === 'Imperial') return 'mph';
    if (units === 'Knot') return 'KN';
    return 'km/h';
  }

  formatTemp(val, { withUnits = false, precision = 0 } = {}) {
    let cval = this.current.temperature === 'Imperial' ? CelsiusToFahrenheit(val) : val;
    cval = cval.toFixed(precision);
    return withUnits ? `${cval}${this.formatTempUnits()}` : cval;
  }

  formatTempUnits() {
    return this.current.temperature === 'Imperial' ? '\u00B0F' : '\u00B0C';
  }

  formatLevel(val) {
    let cval = val;

    if (this.current.length === 'Imperial') {
      cval = roundBy(MetresToFeet(val), 100);
    } else {
      cval = roundBy(cval, 50);
    }

    return cval;
  }
}

export default Units;
