import ArrowCircle from 'components/arrow-circle';
import createSVGElement from 'utils/create-svg-element';

const radius = 11.44;

const periodReferenceColors = [
  [0, [220, 220, 255]],
  [2, [220, 220, 255]],
  [4, [180, 180, 255]],
  [6, [150, 150, 255]],
  [8, [100, 100, 255]],
  [10, [50, 50, 255]],
  [12, [0, 0, 255]],
  [14, [0, 0, 200]],
  [16, [0, 0, 150]],
  [18, [0, 0, 100]],
  [20, [0, 0, 140]],
  [22, [0, 0, 50]],
];
const periodLongest = 22;
const periodEvenLongerColor = [0, 0, 0];
const periodNoneColor = [170, 170, 170];

function textColor(period) {
  if (!period || period < 6) return '#000';
  return 'rgb(254,254,254)';
}

function interpolatedPeriodColor(period) {
  if (Number.isNaN(period)) return periodNoneColor;
  if (period > periodLongest) return periodEvenLongerColor;

  let lowerThreshold;
  let lowerColor;
  let upperThreshold;
  let upperColor;
  for (let i = 0; i < periodReferenceColors.length - 1; i += 1) {
    if (periodReferenceColors[i + 1][0] >= period) {
      [lowerThreshold, lowerColor] = periodReferenceColors[i];
      [upperThreshold, upperColor] = periodReferenceColors[i + 1];
      break;
    }
  }

  if (period === upperThreshold) return upperColor;
  if (period === lowerThreshold) return lowerColor;

  const lowerFraction = (upperThreshold - period) / (upperThreshold - lowerThreshold);
  const rgb = [];
  for (let i = 0; i < 3; i += 1) {
    rgb.push(Math.round(lowerColor[i] * lowerFraction + upperColor[i] * (1 - lowerFraction)));
  }
  return rgb;
}

export function iconColor(period, height) {
  const val = interpolatedPeriodColor(period);
  if (height < 0) {
    return `rgb(${val[2]},${val[1]},${val[0]})`; // exchanging blue and red components
  }

  return `rgb(${val.join(',')})`;
}

function interpolate(a, b, p) {
  const minPeriod = 2;
  const maxPeriod = 22;
  return p ? a + (b - a) * p / (maxPeriod - minPeriod) : a;
}


export default class SwellIcon {
  constructor({ el, units, iconWidth = 38, iconHeight = 38 }) {
    this.units = units;

    if (el) {
      this.el = el;
      this.svgEl = el.querySelector('.swell-icon__svg');
      this.arrow = new ArrowCircle(el.querySelector('.swell-icon__arrow'));
      this.textEl = el.querySelector('.swell-icon__val');

      this.setHeight(el.dataset.height);
      this.renderHeight();
    } else {
      this.create({ iconWidth, iconHeight });
    }

    this.units.onChange(() => this.renderHeight());
  }

  setHeight(heightStr) {
    const h = parseFloat(heightStr);
    this.height = Number.isNaN(h) ? null : h;
  }

  setPeriod(periodStr) {
    this.period = parseInt(periodStr, 10);
  }

  renderHeight() {
    this.textEl.textContent = this.height !== null
      ? this.units.formatSwell(Math.abs(this.height))
      : '-';
  }

  create({ iconWidth, iconHeight }) {
    this.el = document.createElement('div');
    this.el.classList.add('swell-icon');

    this.svgEl = createSVGElement('svg');
    this.svgEl.setAttribute('viewBox', `${- iconWidth / 2} ${- iconHeight / 2} ${iconWidth} ${iconHeight}`);
    this.svgEl.setAttribute('class', 'swell-icon__svg');
    this.el.appendChild(this.svgEl);

    const circle = createSVGElement('circle');
    circle.setAttribute('cx', 0);
    circle.setAttribute('cy', 0);
    circle.setAttribute('r', radius);
    this.svgEl.appendChild(circle);

    this.arrow = new ArrowCircle();
    this.arrow.el.setAttribute('class', 'swell-icon__arrow');
    this.svgEl.appendChild(this.arrow.el);

    this.textEl = createSVGElement('text');
    this.textEl.setAttribute('x', 0);
    this.textEl.setAttribute('y', 5);
    this.textEl.setAttribute('text-anchor', 'middle');
    this.textEl.setAttribute('textLength', '17');
    this.textEl.setAttribute('class', 'swell-icon__val');
    this.svgEl.appendChild(this.textEl);
  }

  update({ height, angle, period }) {
    this.setHeight(height);
    this.setPeriod(period);

    this.el.classList.toggle('swell-icon--sheltered', height < 0);
    this.svgEl.setAttribute('fill', iconColor(this.period, this.height));

    this.arrow.update({
      angle,
      radius,
      arrowheadHeight: 6,
      arrowheadThickness: interpolate(10, 30, this.period),
      connectorHeight: 2,
      connectorThickness: interpolate(4, 8, this.period),
    });

    this.renderHeight();
    this.textEl.setAttribute('fill', textColor(this.period));
  }
}
